import { FC } from 'react'
import { Route, Switch } from 'wouter'
import { observer } from 'mobx-react'

import { InitProcessPage } from '@/pages/InitProcessPage'
import { NotFoundPage } from '@/pages/NotFoundPage'
import { SuccessPage } from '@/pages/SuccessPage'
import { PersonalDetailsPage } from '@/pages/PersonalDetailsPage'
import { ErrorPage } from '@/pages/ErrorPage'

export const ContentRouter: FC = observer(() => {
  return (
    <Switch>
      {/* #1 Init */}
      <Route path="/fill-debtor-data" component={PersonalDetailsPage} />
      <Route path="/terms" component={PersonalDetailsPage} />

      {/* #2 Openbanking onboarding-web flow */}

      {/* #3 PDF Sign */}
      <Route path="/" component={InitProcessPage} />
      <Route path="/success" component={SuccessPage} />

      <Route path="/error" component={ErrorPage} />
      {/* Default route in a switch */}
      <Route component={NotFoundPage} />
    </Switch>
  )
})
