import { action, makeAutoObservable } from 'mobx'
import PDF from '../assets/privacy_policy.pdf'
import { toBase64 } from '@/methods/utils'

export type Step = 'init' | 'pdf' | 'personalDetails' | 'generalConditions'
export type Theme = 'mastercard'

const DEV_MODE = process.env.DEV_MODE === 'true'

export interface DebtorData {
  firstName?: string
  lastName?: string
  address?: {
    city?: string
    postalCode?: string
    country?: string
  }
  iban?: string
}

export class AppStateStore {
  DEV_MODE = DEV_MODE || false
  rootStore
  theme = 'mastercard' as Theme

  step = 'init'
  pdfBase64 = ''
  screenSize = {
    width: 0,
    height: 0,
  }

  debtorData: DebtorData = {
    firstName: '',
    lastName: '',
    address: {
      city: '',
      postalCode: '',
      country: '',
    },
    iban: '',
  }

  debtorFormValidationErrors = {
    firstNameError: undefined,
    lastNameError: undefined,
    cityError: undefined,
    postalCodeError: undefined,
    countryError: undefined,
  }

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore

    if (DEV_MODE) {
      this.initDevPdf()
    }
  }

  @action.bound async initDevPdf() {
    const basePDF = (await toBase64(PDF)) as string
    this.pdfBase64 = basePDF.split('base64,')[1]
  }

  @action.bound setTheme(theme: Theme) {
    this.theme = theme
  }

  @action.bound setStep(step: Step) {
    this.step = step
  }

  @action.bound setPdfBase64(pdfBase64: string) {
    this.pdfBase64 = pdfBase64
  }

  @action.bound setScreenSize(screenSize: { width: number; height: number }): void {
    this.screenSize = screenSize
  }

  @action.bound setFirstName(firstName: string) {
    this.debtorData.firstName = firstName
  }

  @action.bound setLastName(lastName: string) {
    this.debtorData.lastName = lastName
  }

  @action.bound setCountry(country: string) {
    this.debtorData.address.city = ''

    this.debtorData.address.country = country
  }

  @action.bound setCity(city: string) {
    this.debtorData.address.city = city
  }

  @action.bound setPostal(postal: string) {
    this.debtorData.address.postalCode = postal
  }

  @action.bound resetDebtorData() {
    this.debtorData = {
      firstName: '',
      lastName: '',
      address: {
        city: '',
        postalCode: '',
        country: '',
      },
      iban: '',
    }
  }

  @action.bound setDebtorData(debtorData: DebtorData) {
    this.debtorData = {
      ...this.debtorData,
      ...debtorData,
    }
  }

  @action.bound setFormError(property: keyof typeof this.debtorFormValidationErrors, error: string) {
    this.debtorFormValidationErrors[property] = error
  }

  @action.bound clearFormError(property: keyof typeof this.debtorFormValidationErrors) {
    this.debtorFormValidationErrors[property] = undefined
  }
}
