import { Container, ContentContainerBottomFixed, ProceedButton } from '@/styles/generic.styles'
import { FC, useState } from 'react'
import { LoadingSpinner } from '../../LoadingSpinner'
import {
  ConsentCheckbox,
  ConsentContainer,
  ConsentFooter,
  ConsentInfo,
  ConsentLink,
  ConsentTitle,
  Icon,
  Info,
  InfoScreen,
  SeparatorLine,
  Title,
  TitleInfo,
} from './GeneralConditions.styles'
import { Checkbox } from '@/components/Checkbox/Checkbox'
import { useTheme } from 'styled-components'
import { API_URL } from '@/constants/envs'
import PrivacyPolicy from '@/assets/privacy_policy.pdf'

export const GeneralConditions: FC = () => {
  const theme = useTheme()

  const [checked, setChecked] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleProceed = async () => {
    if (!checked) {
      return
    }

    setIsLoading(true)
    // redirect to AIS
    window.location.replace(`${API_URL}/api/redirect-to-ais`)
  }

  return (
    <Container>
      <Title>General conditions</Title>

      <InfoScreen>
        <Icon>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.99998 3.04165C6.157 3.04165 3.04165 6.157 3.04165 9.99998C3.04165 13.843 6.157 16.9583 9.99998 16.9583C13.843 16.9583 16.9583 13.843 16.9583 9.99998C16.9583 6.157 13.843 3.04165 9.99998 3.04165ZM1.95831 9.99998C1.95831 5.55869 5.55869 1.95831 9.99998 1.95831C14.4413 1.95831 18.0416 5.55869 18.0416 9.99998C18.0416 14.4413 14.4413 18.0416 9.99998 18.0416C5.55869 18.0416 1.95831 14.4413 1.95831 9.99998Z"
              fill="#CF4500"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.99998 14.7083C9.70083 14.7083 9.45831 14.4658 9.45831 14.1666L9.45831 9.16665C9.45831 8.86749 9.70083 8.62498 9.99998 8.62498C10.2991 8.62498 10.5416 8.86749 10.5416 9.16665V14.1666C10.5416 14.4658 10.2991 14.7083 9.99998 14.7083Z"
              fill="#CF4500"
            />
            <path
              d="M10.625 6.45831C10.625 6.11313 10.3452 5.83331 9.99998 5.83331C9.6548 5.83331 9.37498 6.11313 9.37498 6.45831C9.37498 6.80349 9.6548 7.08331 9.99998 7.08331C10.3452 7.08331 10.625 6.80349 10.625 6.45831Z"
              fill="#CF4500"
            />
          </svg>
        </Icon>
        <TitleInfo>We will verify the following bank account data:</TitleInfo>
        <Info>
          <li>Account holder name </li>
          <li>Account IBAN </li>
          {/* <li>Bank account BIC number </li> */}
        </Info>
      </InfoScreen>

      <SeparatorLine />

      <ConsentContainer>
        <ConsentTitle>
          Terms and Conditions of use <span>*</span>
        </ConsentTitle>

        <ConsentCheckbox>
          <Checkbox value={checked} onChange={setChecked} />
        </ConsentCheckbox>

        <ConsentInfo onClick={() => setChecked(!checked)}>
          I consent to the use of my personal data for identifying myself with Signicat Open Banking Verification
          services
        </ConsentInfo>

        <ConsentLink href={PrivacyPolicy} download="Open_Banking_Privacy_Policy.pdf">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14.762 4.49998C14.762 4.14099 15.053 3.84998 15.412 3.84998H19.4C19.8142 3.84998 20.15 4.18576 20.15 4.59997L20.15 8.64097C20.15 8.99996 19.859 9.29097 19.5 9.29098C19.141 9.29098 18.85 8.99996 18.85 8.64098L18.85 6.06921L12.9596 11.9596C12.7058 12.2134 12.2942 12.2134 12.0404 11.9596C11.7865 11.7058 11.7865 11.2942 12.0404 11.0404L17.9307 5.14998H15.412C15.053 5.14998 14.762 4.85896 14.762 4.49998ZM3.84998 9.49998C3.84998 6.93185 5.93185 4.84998 8.49998 4.84998H11.5C11.859 4.84998 12.15 5.14099 12.15 5.49998C12.15 5.85896 11.859 6.14998 11.5 6.14998H8.49998C6.64982 6.14998 5.14998 7.64982 5.14998 9.49998V15.5C5.14998 17.3501 6.64982 18.85 8.49998 18.85H14.5C16.3501 18.85 17.85 17.3501 17.85 15.5V12.5C17.85 12.141 18.141 11.85 18.5 11.85C18.859 11.85 19.15 12.141 19.15 12.5V15.5C19.15 18.0681 17.0681 20.15 14.5 20.15H8.49998C5.93185 20.15 3.84998 18.0681 3.84998 15.5V9.49998Z"
              fill="#303438"
            />
          </svg>
        </ConsentLink>
      </ConsentContainer>

      <ConsentFooter>* required consent</ConsentFooter>

      <ContentContainerBottomFixed $borderTop="none" $paddingMobile="20px" $isDarkerBg>
        <ProceedButton disabled={!checked || isLoading} onClick={handleProceed}>
          {isLoading ? (
            <LoadingSpinner {...theme.loadingSpinner} width="16px" padding="0 0" thickness={2} />
          ) : (
            <>Continue</>
          )}
        </ProceedButton>
      </ContentContainerBottomFixed>
    </Container>
  )
}
