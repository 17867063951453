import React from "react";

interface LockClosedProps {
  color: string;
  thickness?: string;
}

export const LockClosed = ({ color, thickness }: LockClosedProps) => {
  return (
    <svg
      width="800px"
      height="800px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 10V7C6 5.4087 6.63214 3.88258 7.75736 2.75736C8.88258 1.63214 10.4087 1 12 1C13.5913 1 15.1174 1.63214 16.2426 2.75736C17.3679 3.88258 18 5.4087 18 7V10H19C20.6569 10 22 11.3431 22 13V20C22 21.6569 20.6569 23 19 23H5C3.34315 23 2 21.6569 2 20V13C2 11.3431 3.34315 10 5 10H6ZM9.17157 4.17157C9.92172 3.42143 10.9391 3 12 3C13.0609 3 14.0783 3.42143 14.8284 4.17157C15.5786 4.92172 16 5.93913 16 7V10H8V7C8 5.93913 8.42143 4.92172 9.17157 4.17157ZM5 12C4.44772 12 4 12.4477 4 13V20C4 20.5523 4.44772 21 5 21H19C19.5523 21 20 20.5523 20 20V13C20 12.4477 19.5523 12 19 12H5Z"
        fill={`${color ? color : "#280b60"}`}
        stroke={`${color ? color : "#280b60"}`}
        strokeWidth={`${thickness ? thickness : "0"}`}
        strokeLinecap="round"
        className="colorized--fill colorized--stroke"
      />
    </svg>
  );
};
