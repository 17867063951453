import { FC } from 'react'
import { PreviewPdf } from './PreviewPdf'
import { Loading } from './Loading'
import { PersonalDetails } from './PersonalDetails/PersonalDetails'
import { GeneralConditions } from './GeneralConditions/GeneralConditions'

const StepMap = {
  init: Loading,
  pdf: PreviewPdf,
  personalDetails: PersonalDetails,
  generalConditions: GeneralConditions,
}

export const Matrix: FC<{ step: string }> = ({ step }) => {
  const Handler = StepMap[step]

  return <Handler />
}
