import React from "react";

interface TurnRightProps {
  color: string;
  thickness?: string;
}

export const TurnRight = ({ color, thickness }: TurnRightProps) => {
  return (
    <svg
      width="800px"
      height="800px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0874 2.02906C12.7431 1.96564 12.3883 2.00663 12.0678 2.14611C11.7474 2.2855 11.4771 2.51629 11.2883 2.80727C11.0997 3.09808 11.0001 3.43711 11 3.78241L11 7.03095C7.25861 7.26795 4.72577 8.85318 3.15451 11.341C1.5013 13.9586 1 17.4414 1 21C1 21.424 1.26733 21.8018 1.66711 21.943C2.06688 22.0841 2.5122 21.9578 2.77837 21.6278C4.01283 20.0973 4.90572 18.9971 6.17555 18.2297C7.26436 17.5717 8.70545 17.1206 11 17.0208L11 20.2164C11.0001 20.5617 11.0997 20.9008 11.2883 21.1916C11.4771 21.4826 11.7474 21.7134 12.0678 21.8528C12.3883 21.9922 12.7431 22.0332 13.0874 21.9698C13.4316 21.9064 13.7476 21.742 13.9972 21.4997L22.4587 13.2827C22.6309 13.1154 22.7672 12.9151 22.8599 12.6947C22.9525 12.4744 23 12.238 23 11.9994C23 11.7609 22.9525 11.5244 22.8599 11.3041C22.7672 11.0838 22.6312 10.8837 22.459 10.7164L13.9973 2.49935C13.7478 2.25698 13.4316 2.09244 13.0874 2.02906ZM13 4.31872L13 9H12C8.29522 9 6.13808 10.3624 4.84549 12.409C3.88672 13.927 3.35775 15.8935 3.13254 18.1422C3.72922 17.5324 4.37809 16.9791 5.14111 16.518C6.7965 15.5176 8.89011 15 12 15H13L13 19.6801L20.9094 11.9994L13 4.31872Z"
        fill={`${color ? color : "#280b60"}`}
        stroke={`${color ? color : "#280b60"}`}
        strokeWidth={`${thickness ? thickness : "0"}`}
        strokeLinecap="round"
        className="colorized--fill colorized--stroke"
      />
    </svg>
  );
};
