import React from "react";

interface PrinterProps {
  color: string;
  thickness?: string;
}

export const Printer = ({ color, thickness }: PrinterProps) => {
  return (
    <svg
      width="800px"
      height="800px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 3C5 2.44772 5.44772 2 6 2H15C15.2652 2 15.5196 2.10536 15.7071 2.29289L18.7071 5.29289C18.8946 5.48043 19 5.73478 19 6V8H20C21.6569 8 23 9.34315 23 11V16C23 17.1046 22.1046 18 21 18H19V21C19 21.5523 18.5523 22 18 22H6C5.44772 22 5 21.5523 5 21V18H3C1.89543 18 1 17.1046 1 16V11C1 9.34315 2.34315 8 4 8H5V3ZM4 10C3.44772 10 3 10.4477 3 11V16H5V14C5 13.4477 5.44772 13 6 13H18C18.5523 13 19 13.4477 19 14V16H21V11C21 10.4477 20.5523 10 20 10H4ZM17 8H7V4H14.5858L17 6.41421V8ZM7 15V20H17V15H7Z"
        fill={`${color ? color : "#280b60"}`}
        stroke={`${color ? color : "#280b60"}`}
        strokeWidth={`${thickness ? thickness : "0"}`}
        strokeLinecap="round"
        className="colorized--fill colorized--stroke"
      />
    </svg>
  );
};
