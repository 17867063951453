import {
  Container,
  ContentContainerBottomFixed,
  ProceedButton,
  RequiredAsterisk,
  Spacer,
} from '@/styles/generic.styles'
import { FC, useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { StoreContext } from '../../App'
import { Form, FormSubtitle, FormTitle, InputError, Label, TextInput } from './PersonalDetails.styles'
import { CountrySelect, CountrySelectItem } from '@/components/CountrySelect/CountrySelect'
import { LoadingSpinner } from '@/components/LoadingSpinner'
import { useTheme } from 'styled-components'
import { axiosInstance } from '@/methods/axiosConfig'
import { countryList } from '@/components/CountrySelect/countryListAlpha2'
import Select from 'react-select'
import { netherlandsCityList } from '@/constants/netherlandsCityList'
import { DEV_MODE } from '@/constants/envs'
import { CitySearchInput, SelectItem } from '@/components/CitySearchInput/CitySearchInput'

export const PersonalDetails: FC = observer(() => {
  const store = useContext(StoreContext)
  const theme = useTheme()
  const { firstName, lastName, iban } = store.AppState.debtorData
  const { firstNameError, lastNameError, cityError, postalCodeError } = store.AppState.debtorFormValidationErrors
  const { country, city, postalCode } = store.AppState.debtorData.address

  const [isLoading, setIsLoading] = useState(false)

  const proceed = async () => {
    if (!isValid) {
      validateAll()
      return
    }

    setIsLoading(true)
    try {
      await axiosInstance.put(`/api/debtor-data`, {
        debtor: store.AppState.debtorData,
      })
      store.AppState.setStep('generalConditions')
    } catch (error) {
      console.log(error)
    }
    setIsLoading(false)
  }

  const isValid = !!firstName && !!lastName && !!city && !!postalCode && !!country

  const handleValidation = (
    value: string,
    errorProperty: keyof typeof store.AppState.debtorFormValidationErrors,
    setValue?: (val: string) => void,
  ) => {
    if (!value || !value?.length) {
      errorProperty && store.AppState.setFormError(errorProperty, 'Field cannot be empty.')
      return false
    }

    store.AppState.clearFormError(errorProperty)
    setValue && setValue(value)
    return true
  }

  const validateAll = () => {
    handleValidation(firstName, 'firstNameError')
    handleValidation(lastName, 'lastNameError')
    country !== 'NL' && handleValidation(city, 'cityError')
    handleValidation(postalCode, 'postalCodeError')
  }

  useEffect(() => {
    if (DEV_MODE) {
      console.log({
        firstName,
        lastName,
        address: {
          city,
          postalCode,
          country,
        },
      })
    }
  }, [firstName, lastName, country, city, postalCode])

  const [cityInputKey, setCityInputKey] = useState(0)

  useEffect(() => {
    setCityInputKey((prev) => prev + 1)
  }, [country])

  return (
    <Container>
      <Form>
        <FormTitle>Personal Details</FormTitle>
        <FormSubtitle>Please complete the following form with your personal details</FormSubtitle>

        <Label htmlFor="first-name">
          First name <RequiredAsterisk>*</RequiredAsterisk>
        </Label>
        <TextInput
          name="first-name"
          type="text"
          autoComplete="given-name"
          value={firstName}
          onChange={(e) => store.AppState.setFirstName(e.target.value)}
          onBlur={(e) =>
            handleValidation(e.target.value, 'firstNameError', () => {
              store.AppState.setFirstName(e.target.value)
            })
          }
          $isError={firstNameError}
        />
        {firstNameError && <InputError>{firstNameError}</InputError>}

        <Label htmlFor="last-name">
          Last name <RequiredAsterisk>*</RequiredAsterisk>
        </Label>
        <TextInput
          name="last-name"
          type="text"
          autoComplete="family-name"
          value={lastName}
          onChange={(e) => store.AppState.setLastName(e.target.value)}
          onBlur={(e) =>
            handleValidation(e.target.value, 'lastNameError', () => {
              store.AppState.setLastName(e.target.value)
            })
          }
          $isError={lastNameError}
        />
        {lastNameError && <InputError>{lastNameError}</InputError>}

        <Label htmlFor="country">
          Country <RequiredAsterisk>*</RequiredAsterisk>
        </Label>
        <CountrySelect
          name="country"
          value={countryList.find((x) => x.value === country)}
          onChange={(val: CountrySelectItem) => store.AppState.setCountry(val.value)}
          error={!country}
        />
        {/* {!country && <InputError>{'Field cannot be empty.'}</InputError>} */}

        <Spacer size={30} />

        <Label htmlFor="city">
          City <RequiredAsterisk>*</RequiredAsterisk>
        </Label>
        {/* {country !== 'NL' ? (
          <>
            <TextInput
              name="city"
              type="text"
              autoComplete="address-level2"
              value={city}
              onChange={(e) => store.AppState.setCity(e.target.value)}
              onBlur={(e) =>
                handleValidation(e.target.value, 'cityError', () => {
                  store.AppState.setCity(e.target.value)
                })
              }
              $isError={cityError}
            />
            {cityError && <InputError>{cityError}</InputError>}
          </>
        ) : (
          <>
            <Select
              name="city"
              value={{
                label: city,
                value: city,
              }}
              onChange={(val: CountrySelectItem) => store.AppState.setCity(val.value)}
              options={netherlandsCityList.map((city) => ({ label: city, value: city }))}
              classNamePrefix="react-select"
            />
            <Spacer size={30} />
          </>
        )} */}
        <CitySearchInput
          key={cityInputKey}
          countryCode={country}
          value={{
            label: city,
            value: city,
          }}
          onChange={(val: SelectItem) => store.AppState.setCity(val.label)}
        />
        <Spacer size={30} />

        <Label htmlFor="postal">
          Postal code <RequiredAsterisk>*</RequiredAsterisk>
        </Label>
        <TextInput
          name="postal"
          type="text"
          autoComplete="postal-code"
          value={postalCode}
          onChange={(e) => store.AppState.setPostal(e.target.value)}
          onBlur={(e) =>
            handleValidation(e.target.value, 'postalCodeError', () => {
              store.AppState.setPostal(e.target.value)
            })
          }
          $isError={postalCodeError}
        />
        {postalCodeError && <InputError>{postalCodeError}</InputError>}

        {!!iban?.length && (
          <>
            <Label htmlFor="iban">IBAN</Label>
            <TextInput name="iban" type="text" autoComplete="postal-code" value={iban} disabled />
          </>
        )}

        <Spacer size={32} $mobileOnly />

        <ContentContainerBottomFixed $borderTop="none" $paddingMobile="20px" $isDarkerBg>
          <ProceedButton onClick={proceed} type="button" disabled={isLoading} $disabled={!isValid}>
            {isLoading ? (
              <LoadingSpinner {...theme.loadingSpinner} width="16px" padding="0 0" thickness={2} />
            ) : (
              <>Continue</>
            )}
          </ProceedButton>
        </ContentContainerBottomFixed>
      </Form>
    </Container>
  )
})
