import React from "react";

interface EnvelopeProps {
  color: string;
  thickness?: string;
}

export const Envelope = ({ color, thickness }: EnvelopeProps) => {
  return (
    <svg
      width="800px"
      height="800px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 6V18C23 19.6523 21.6523 21 20 21H4C2.34772 21 1 19.6523 1 18V6C1 4.34315 2.34315 3 4 3H20C21.6569 3 23 4.34315 23 6ZM3.10658 5.55395C3.27196 5.22692 3.61204 5 4 5H20C20.388 5 20.728 5.22692 20.8934 5.55395L12 11.7793L3.10658 5.55395ZM3 7.92066L10.8531 13.4178C11.5417 13.8999 12.4583 13.8999 13.1469 13.4178L21 7.92066V18C21 18.5477 20.5477 19 20 19H4C3.45228 19 3 18.5477 3 18V7.92066Z"
        fill={`${color ? color : "#280b60"}`}
        stroke={`${color ? color : "#280b60"}`}
        strokeWidth={`${thickness ? thickness : "0"}`}
        strokeLinecap="round"
        className="colorized--fill colorized--stroke"
      />
    </svg>
  );
};
