import { FC, useContext, useEffect, useRef, useState } from 'react'
import { PdfViewer } from '../PdfViewer/PdfViewer'
import { useTheme } from 'styled-components'
import { observer } from 'mobx-react'
import { StoreContext } from '../App'
import { Button } from '../PdfViewer/Button/Button'
import { ContentContainer } from '../ContentContainer/ContentContainer'
import { Header } from '../Header/Header'
import { getViewerHeight } from '@/methods/utils'
import { LoadingSpinner } from '../LoadingSpinner'
import { ButtonImgWrap, CheckboxForm, ContentContainerBottomFixed, ContentToLeft } from '@/styles/generic.styles'
import { Icon } from '../Icon/Icon'
import { navigate } from 'wouter/use-browser-location'
import { axiosInstance } from '@/methods/axiosConfig'

export const PreviewPdf: FC = observer(() => {
  const theme = useTheme()
  const store = useContext(StoreContext)
  const { pdfBase64, screenSize } = store.AppState

  const [btnLoading, setBtnLoading] = useState(false)

  const [documentScrolled, setDocumentScrolled] = useState(false)
  const [signed, setSigned] = useState(false)
  const [mobileVersion, setMobileVersion] = useState(false)
  const [containerHeight, setContainerHeight] = useState('')
  const headerRef = useRef(null)
  const footerRef = useRef(null)

  function simulateClick(e) {
    e.click()
  }

  useEffect(() => {
    if (screenSize.width && screenSize.width <= 980) {
      setMobileVersion(true)
      setContainerHeight(`calc(${window.innerHeight}px - ${footerRef.current.offsetHeight}px)`)
    }
  }, [screenSize])

  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop - 50 <= e.target.clientHeight

    if (bottom) {
      setDocumentScrolled(true)
    }
  }

  const sign = async () => {
    setBtnLoading(true)
    try {
      await axiosInstance.post('/api/sign-document')

      navigate('/success')
    } catch (error) {
      console.error(error)

      setBtnLoading(false)
    }
  }

  return (
    <ContentContainer
      {...theme.container}
      width="1000px"
      minHeight="450px"
      paddingMobile="20px"
      heightMobile={containerHeight}
      marginMobile="0px"
      ref={simulateClick}
    >
      <Header {...theme.header} fontFamily={theme.globals.fontFamilyHeadline}>
        <span ref={headerRef}>Sign document</span>
      </Header>
      {pdfBase64 && pdfBase64.length ? (
        mobileVersion ? (
          <PdfViewer
            file={'data:application/pdf;base64,' + pdfBase64}
            {...theme.pdfViewer}
            canvasWidth={348}
            viewerWidth="100%"
            containerHeight={getViewerHeight(
              screenSize,
              headerRef.current.offsetHeight,
              footerRef.current.offsetHeight,
            )}
            viewerHeight="100%"
            initialScale={1}
            margin="30px 0 0 0"
            containerPadding="0px"
            navigationSpacerColor="none"
            onScrollHandler={handleScroll}
          />
        ) : (
          <PdfViewer
            file={'data:application/pdf;base64,' + pdfBase64}
            {...theme.pdfViewer}
            canvasWidth={865}
            viewerWidth="100%"
            viewerHeight={getViewerHeight(screenSize)}
            initialScale={1}
            navigationSpacerColor="none"
            onScrollHandler={handleScroll}
          />
        )
      ) : (
        <LoadingSpinner {...theme.loadingSpinner} width="68px" padding="100px 0" height={getViewerHeight(screenSize)} />
      )}

      <ContentContainerBottomFixed ref={footerRef}>
        <CheckboxForm>
          <input
            type="checkbox"
            name="sign"
            value={signed ? 'true' : 'false'}
            id="sign"
            onChange={() => setSigned(!signed)}
          />
          <label className="label-text" htmlFor="sign">
            I acknowledge that I have read and understand the terms of this agreement as detailed above.
          </label>
        </CheckboxForm>
        <ContentToLeft>
          <Button
            onClick={sign}
            {...theme.button}
            disabled={pdfBase64.length === 0 || btnLoading}
            minWidth="224px"
            paddingMobile="14px 40px"
            id="page-submit"
          >
            {btnLoading ? (
              <LoadingSpinner {...theme.loadingSpinner} width="16px" padding="0 0" thickness={2} />
            ) : (
              <>
                <ButtonImgWrap>
                  <Icon type="pencil" size="18px" />
                </ButtonImgWrap>
                Sign
              </>
            )}
          </Button>
        </ContentToLeft>
      </ContentContainerBottomFixed>
    </ContentContainer>
  )
})
