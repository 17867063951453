import React from "react";

interface EditProps {
  color: string;
  thickness?: string;
}

export const Edit = ({ color, thickness }: EditProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M2.49805 17.5001H5.62305L14.8397 8.28346L11.7147 5.15846L2.49805 14.3751V17.5001ZM4.16471 15.0668L11.7147 7.5168L12.4814 8.28346L4.93138 15.8335H4.16471V15.0668Z"
        fill={color ? color : "#280b60"}
      />
      <path
        d="M15.3064 2.7418C14.9814 2.4168 14.4564 2.4168 14.1314 2.7418L12.6064 4.2668L15.7314 7.3918L17.2564 5.8668C17.5814 5.5418 17.5814 5.0168 17.2564 4.6918L15.3064 2.7418Z"
        fill={color ? color : "#280b60"}
      />
    </svg>
  );
};
