import { FC, useRef } from 'react'
import { CheckboxCheckmark, CheckboxContainer } from './Checkbox.styles'

interface CheckboxProps {
  value: boolean
  onChange: (value: boolean) => void
}

export const Checkbox: FC<CheckboxProps> = ({ value, onChange }) => {
  const ref = useRef<HTMLInputElement>(null)
  return (
    <CheckboxContainer
      onClick={() => {
        if (ref.current) {
          ref.current.click()
        }
      }}
      $checked={value}
    >
      <input type="checkbox" checked={value} onChange={(e) => onChange(e.target.checked)} ref={ref} />

      <CheckboxCheckmark $checked={value}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path d="M9 22l-10-10.598 2.798-2.859 7.149 7.473 13.144-14.016 2.909 2.806z" fill="currentColor" />
        </svg>
      </CheckboxCheckmark>
    </CheckboxContainer>
  )
}
