import { styled } from 'styled-components'

export const Title = styled.h1`
  color: #34353a;
  text-align: center;

  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  margin: 0 0 30px 0;
`

export const InfoScreen = styled.div`
  background: var(--Background-secondary, #f7f9fc);
  width: 100%;
  display: grid;
  grid-template-columns: 20px 1fr;
  grid-template-rows: 20px 1fr;
  gap: 2px 15px;
  padding: 16px;

  grid-template-areas:
    'icon title'
    '____ info';

  @media (max-width: 700px) {
    grid-template-rows: auto 1fr;
  }
`

export const Icon = styled.div`
  grid-area: icon;
  width: 20px;
  height: 20px;
`

export const TitleInfo = styled.h2`
  grid-area: title;

  color: var(--Text-header-primary, #2b253a);
  font-family: Inter;
  font-size: var(--Fonts-Size-Paragraph-4, 14px);
  font-style: normal;
  font-weight: 600;
  line-height: var(--Fonts-Line-height-Paragraph-4, 20px);
`

export const Info = styled.ul`
  grid-area: info;

  color: var(--Text-secondary, #565b60);
  font-family: Inter;
  font-size: var(--Fonts-Size-Paragraph-4, 14px);
  font-style: normal;
  font-weight: 400;
  line-height: var(--Fonts-Line-height-Paragraph-4, 20px);
`

export const SeparatorLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #cecbd6;
  margin: 30px 0;
`

export const ConsentContainer = styled.div`
  display: grid;
  grid-template-columns: 28px 1fr 1fr 110px;
  grid-template-rows: 20px auto;
  gap: 10px 2px;
  grid-template-areas:
    'title     title  title   title'
    'checkbox  info   info   link';

  @media (max-width: 700px) {
    grid-template-columns: 28px 1fr 1fr 40px;
  }
`

export const ConsentTitle = styled.h2`
  grid-area: title;

  color: var(--Text-header-primary, #2b253a);

  /* Paragraph 4 - Medium */
  font-family: Inter;
  font-size: var(--Fonts-Size-Paragraph-4, 14px);
  font-style: normal;
  font-weight: 500;
  line-height: var(--Fonts-Line-height-Paragraph-4, 20px); /* 142.857% */

  span {
    color: #cf4500;

    /* Paragraph 4 - Medium */
    font-family: Inter;
    font-size: var(--Fonts-Size-Paragraph-4, 14px);
    font-style: normal;
    font-weight: 500;
    line-height: var(--Fonts-Line-height-Paragraph-4, 20px);
  }
`

export const ConsentCheckbox = styled.div`
  grid-area: checkbox;
  display: flex;
  justify-content: center;

  width: 20px;
  height: 20px;

  /* border-radius: 4px;
  border: 2px solid black;
  cursor: pointer; */
`

export const ConsentInfo = styled.label`
  grid-area: info;

  color: var(--Text-primary, #25292c);

  /* Paragraph 5 - Regular */
  font-family: Inter;
  font-size: var(--Fonts-Size-Paragraph-5, 12px);
  font-style: normal;
  font-weight: 400;
  line-height: var(--Fonts-Line-height-Paragraph-5, 18px); /* 150% */
`

export const ConsentLink = styled.a`
  width: 36px;
  height: 36px;
  grid-area: link;
  cursor: pointer;

  display: flex;
  justify-self: flex-end;
  align-items: flex-start;
`

export const ConsentFooter = styled.div`
  width: 100%;

  color: #cf4500;

  /* Paragraph 4 - Medium */
  font-family: Inter;
  font-size: var(--Fonts-Size-Paragraph-4, 14px);
  font-style: normal;
  font-weight: 500;
  line-height: var(--Fonts-Line-height-Paragraph-4, 20px); /* 142.857% */

  margin: 15px 0 30px 0;
`
