import { FC, useState } from 'react'
import AsyncSelect from 'react-select/async'

const FCODE_FILTER = ['PPL', 'PPLA', 'PPLA2', 'PPLA3', 'PPLA4', 'PPLC', 'PPLX', 'PPLR']

interface Geoname {
  adminCode1: string
  lng: string
  geonameId: number
  toponymName: string
  countryId: string
  fcl: string
  population: number
  countryCode: string
  name: string
  fclName: string
  adminCodes1: {
    ISO3166_2: string
  }
  countryName: string
  fcodeName: string
  adminName1: string
  lat: string
  fcode: string
}

export interface SelectItem {
  label: string
  value: string
}

interface CitySearchInputProps {
  value?: SelectItem
  onChange?: (value: SelectItem) => void
  countryCode: string
}

export const CitySearchInput: FC<CitySearchInputProps> = ({ value, onChange, countryCode }) => {
  const username = 'hoxton314' // GeoNames username

  const fetchCities = (query) => {
    if (!countryCode) return Promise.resolve([])

    return fetch(
      `https://secure.geonames.org/searchJSON?name_startsWith=${query}&country=${countryCode}&maxRows=100&username=${username}`,
    )
      .then((response) => response.json())
      .then((data: { geonames: Geoname[] }) => {
        console.log(data)
        return data.geonames
          .filter((a) => FCODE_FILTER.includes(a.fcode))
          .slice(0, 10)
          .map((item) => ({
            label: item.name,
            value: item.geonameId,
          }))
      })
  }

  return (
    <AsyncSelect
      name="city"
      value={value}
      onChange={onChange}
      classNamePrefix="react-select"
      // cacheOptions
      loadOptions={fetchCities}
      defaultOptions
      isDisabled={!countryCode}
    />
  )
}
