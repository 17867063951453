import { css, styled } from 'styled-components'

export const Form = styled.form`
  display: flex;
  flex-direction: column;

  .country-select__control {
    border: 1px solid #b7bdc6;
    height: 40px;
  }
`

export const FormTitle = styled.h1`
  color: #34353a;
  text-align: center;

  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;

  margin: 0 0 30px 0;
  width: 100%;
  text-align: center;
`
export const FormSubtitle = styled.h2`
  color: #34353a;

  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  margin: 0 0 30px 0;
  width: 100%;
  text-align: center;
`

export const TextInput = styled.input<{ $isError?: boolean }>`
  margin-bottom: 30px;
  border-radius: 4px;
  border: 1px solid #b7bdc6;
  background: #fff;
  width: 100%;
  height: 40px;
  padding-left: 10px;

  &:disabled {
    background: #f5f5f5;
  }

  ${({ $isError }) =>
    $isError &&
    css`
      border: 1px solid red;
      margin-bottom: 5px;
    `}
`

export const InputError = styled.div`
  width: 100%;
  height: 20px;
  margin-bottom: 5px;
  font-size: 14px;
  color: red;
`

export const Label = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`
