import React from "react";

interface CheckProps {
  color: string;
  thickness?: string;
}

export const Check = ({ color, thickness }: CheckProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5002 8.0998L2.4002 5.9998L1.7002 6.6998L4.5002 9.4998L10.5002 3.4998L9.8002 2.7998L4.5002 8.0998Z"
        fill={color}
        stroke={color}
      />
    </svg>
  );
};
