import { createContext, FC, useContext, useEffect } from 'react'
import { ThemeProvider } from 'styled-components'
import { observer } from 'mobx-react'

import { AppContainer, GlobalStyle } from '@styles/App.styles'
import { Helmet } from './Helmet'
import { ContentRouter } from './ContentRouter'

import { rootStore } from '@store/Root.store'
import { themes } from '@/themes/themes'
import { useScreenSize } from '@/hooks/useScreenSize'
import { DEV_MODE } from '@/constants/envs'

export const StoreContext = createContext(rootStore)

export const App: FC = observer(() => {
  const store = useContext(StoreContext)
  const { theme } = store.AppState

  const screenSize = useScreenSize()

  useEffect(() => {
    rootStore.AppState.setScreenSize(screenSize)
  }, [screenSize])

  useEffect(() => {
    if (DEV_MODE) {
      rootStore.AppState.setStep('generalConditions')
    }
  }, [])

  return (
    <StoreContext.Provider value={rootStore}>
      <Helmet title="AIS signing" description="AIS signing" themeColor="#320822" />
      <ThemeProvider theme={themes[theme]}>
        <GlobalStyle />
        <AppContainer className="app-container">
          <ContentRouter />
        </AppContainer>
      </ThemeProvider>
    </StoreContext.Provider>
  )
})
