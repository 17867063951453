import React from 'react'
import { Container } from './ContentContainer.styles'

interface ContentContainerProps {
  [x: string]: any
  children: React.ReactNode
  bgColor?: string
  padding?: string
  borderRadius?: string
  minWidth?: string
  minHeight?: string
  width?: string
  border?: string
  boxShadow?: string
  margin?: string
  color?: string
  heightMobile?: string
  paddingMobile?: string
  marginMobile?: string
  touchAction?: string
  height?: string
}

export const ContentContainer = ({
  children,
  bgColor,
  padding,
  borderRadius,
  minWidth,
  minHeight,
  height,
  width,
  border,
  boxShadow,
  margin,
  color,
  heightMobile,
  paddingMobile,
  marginMobile,
  touchAction,
}: ContentContainerProps) => {
  return (
    <Container
      $bgColor={bgColor}
      $padding={padding}
      $paddingMobile={paddingMobile}
      $borderRadius={borderRadius}
      $minWidth={minWidth}
      $minHeight={minHeight}
      $height={height}
      $width={width}
      $border={border}
      $boxShadow={boxShadow}
      $margin={margin}
      $heightMobile={heightMobile}
      $marginMobile={marginMobile}
      $color={color}
      $touchAction={touchAction}
    >
      {children}
    </Container>
  )
}
