import React from "react";

interface EmojiSmileProps {
  color: string;
  thickness?: string;
}

export const EmojiSmile = ({ color, thickness }: EmojiSmileProps) => {
  return (
    <svg
      width="800px"
      height="800px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.39992 14.2001C7.84036 13.8697 8.4646 13.9577 8.79678 14.3959L8.80142 14.4018C8.80737 14.4093 8.81869 14.4234 8.83523 14.4431C8.86838 14.4826 8.92211 14.5442 8.99535 14.6207C9.14264 14.7744 9.36399 14.9829 9.65059 15.1913C10.2277 15.611 11.0254 16.0001 11.9999 16.0001C12.9744 16.0001 13.7722 15.611 14.3492 15.1913C14.6359 14.9829 14.8572 14.7744 15.0045 14.6207C15.0777 14.5442 15.1315 14.4826 15.1646 14.4431C15.1812 14.4234 15.1925 14.4093 15.1984 14.4018L15.2031 14.3959C15.5352 13.9577 16.1595 13.8697 16.5999 14.2001C17.0417 14.5314 17.1313 15.1582 16.7999 15.6001L16.7989 15.6014C16.6918 15.7435 16.5714 15.8762 16.4485 16.0045C16.2364 16.2258 15.9265 16.5172 15.5256 16.8088C14.7277 17.3891 13.5254 18.0001 11.9999 18.0001C10.4744 18.0001 9.27219 17.3891 8.47425 16.8088C8.07335 16.5172 7.76344 16.2258 7.55137 16.0045C7.42882 15.8766 7.30914 15.7443 7.20208 15.6029C6.87354 15.1664 6.96219 14.5284 7.39992 14.2001Z"
        fill={`${color ? color : "#280b60"}`}
        stroke={`${color ? color : "#280b60"}`}
        strokeWidth={`${thickness ? thickness : "0"}`}
        strokeLinecap="round"
        className="colorized--fill colorized--stroke"
      />
      <path
        d="M10.5 9C10.5 9.82843 9.82843 10.5 9 10.5C8.17157 10.5 7.5 9.82843 7.5 9C7.5 8.17157 8.17157 7.5 9 7.5C9.82843 7.5 10.5 8.17157 10.5 9Z"
        fill={`${color ? color : "#280b60"}`}
        stroke={`${color ? color : "#280b60"}`}
        strokeWidth={`${thickness ? thickness : "0"}`}
        strokeLinecap="round"
        className="colorized--fill colorized--stroke"
      />
      <path
        d="M15 10.5C15.8284 10.5 16.5 9.82843 16.5 9C16.5 8.17157 15.8284 7.5 15 7.5C14.1716 7.5 13.5 8.17157 13.5 9C13.5 9.82843 14.1716 10.5 15 10.5Z"
        fill={`${color ? color : "#280b60"}`}
        stroke={`${color ? color : "#280b60"}`}
        strokeWidth={`${thickness ? thickness : "0"}`}
        strokeLinecap="round"
        className="colorized--fill colorized--stroke"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"
        fill={`${color ? color : "#280b60"}`}
        stroke={`${color ? color : "#280b60"}`}
        strokeWidth={`${thickness ? thickness : "0"}`}
        strokeLinecap="round"
        className="colorized--fill colorized--stroke"
      />
    </svg>
  );
};
