import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'

import styled from 'styled-components'

interface ContainerProps {
  viewerWidth?: string
  viewerHeight?: string
  border?: string
  padding?: string
}

export const Container = styled.div<ContainerProps>`
  display: inline-block;
  position: relative;
  width: ${(props) => (props.viewerWidth ? props.viewerWidth : '500px')};
  height: ${(props) => (props.viewerHeight ? props.viewerHeight : '700px')};
  overflow: hidden;
  box-sizing: border-box;
  padding: ${(props) => (props.padding ? props.padding : '0')};
  border: ${(props) => (props.border ? props.border : 'none')};
`

interface NavigationProps {
  top?: string
  left?: string
  bottom?: string
  right?: string
}

export const Navigation = styled.div<NavigationProps>`
  position: absolute;
  top: ${(props) => (props.top ? props.top : '')};
  left: ${(props) => (props.left ? props.left : '0px')};
  right: ${(props) => (props.right ? props.right : '')};
  bottom: ${(props) => (props.bottom ? props.bottom : '30px')};
  width: 100%;
  display: flex;
  justify-content: center;
`

interface NavigationInnerProps {
  bgColor?: string
  borderRadius?: string
  padding?: string
  boxShadow?: string
}

export const NavigationInner = styled.div<NavigationInnerProps>`
  display: flex;
  background-color: ${(props) => (props.bgColor ? props.bgColor : 'rgba(99,99,99,0.8)')};
  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : '8px')};
  box-sizing: border-box;
  padding: ${(props) => (props.padding ? props.padding : '10px 30px')};
  height: 48px;

  p {
    margin: 6px 0;
    padding: 0;
  }

  button {
    font-size: 12px;
  }
`

interface NavigationPagesProps {
  borderColor?: string
}

export const NavigationPages = styled.div<NavigationPagesProps>`
  margin: 0 10px;
  display: flex;
  justify-content: center;
  gap: 10px;
  box-sizing: border-box;
  padding-right: 10px;
  border-right: 1px solid ${(props) => (props.borderColor ? props.borderColor : 'gray')};
`

interface NavigationZoomProps {
  borderColor?: string
}

export const NavigationZoom = styled.div<NavigationZoomProps>`
  display: flex;
  justify-content: left;
  box-sizing: border-box;
  gap: 5px;
  padding-right: 10px;
  border-right: 1px solid ${(props) => (props.borderColor ? props.borderColor : 'gray')};
`

export const NavigationSearch = styled.div`
  display: flex;
  justify-content: right;
  box-sizing: border-box;
`

interface SearchBarProps {
  top?: string
  left?: string
  bottom?: string
  right?: string
}

export const SearchBar = styled.div<SearchBarProps>`
  position: absolute;
  top: ${(props) => (props.top ? props.top : '30px')};
  left: ${(props) => (props.left ? props.left : '0px')};
  right: ${(props) => (props.right ? props.right : '')};
  bottom: ${(props) => (props.bottom ? props.bottom : '')};
  width: 100%;
  display: flex;
  justify-content: center;
`

interface SearchBarInner {
  bgColor?: string
  borderRadius?: string
  padding?: string
  boxShadow?: string
  color?: string
  fontSize?: string
  inputRadius?: string
  inputHeight?: string
  inputColor?: string
  inputBgColor?: string
  inputBgColorFocus?: string
  inputBorder?: string
  inputFocusOutline?: string
  inputFocusBorder?: string
  inputPadding?: string
}

export const SearchBarInner = styled.div<SearchBarInner>`
  background-color: ${(props) => (props.bgColor ? props.bgColor : 'rgba(99,99,99,0.8)')};
  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : '8px')};
  box-sizing: border-box;
  padding: ${(props) => (props.padding ? props.padding : '10px 30px')};
  display: flex;
  flex-direction: column;
  box-shadow: ${(props) => (props.boxShadow ? props.boxShadow : 'none')};

  label {
    font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
    color: ${(props) => (props.color ? props.color : 'black')};
  }

  input {
    border: ${(props) => (props.inputBorder ? props.inputBorder : '1px solid #c2c2c2')};
    border-radius: ${(props) => (props.borderRadius ? props.borderRadius : '8px')};
    height: ${(props) => (props.inputHeight ? props.inputHeight : '32px')};
    color: ${(props) => (props.inputColor ? props.inputColor : 'black')};
    background-color: ${(props) => (props.inputBgColor ? props.inputBgColor : '#ffffff')};
    padding: ${(props) => (props.inputPadding ? props.inputPadding : '10px 15px')};

    &:focus {
      background-color: ${(props) => (props.inputBgColorFocus ? props.inputBgColorFocus : '#f3f3f3')};
      outline: ${(props) => (props.inputFocusOutline ? props.inputFocusOutline : '2px solid orange')};
      border: ${(props) => (props.inputFocusBorder ? props.inputFocusBorder : '#c2c2c2')};
    }
  }
`

interface InnerWrapProps {
  viewerWidth: string
  viewerHeight: string
  scrollHandleColor?: string
  scrollTrackColor?: string
  scrollRadius?: string
}

export const InnerWrap = styled.div.attrs({ className: 'authflow-pdf-viewer-inner-wrap' })<InnerWrapProps>`
  display: inline-block;
  position: relative;
  width: ${(props) => (props.viewerWidth ? props.viewerWidth : '500px')};
  height: ${(props) => (props.viewerHeight ? props.viewerHeight : '700px')};
  overflow: auto;

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-track {
    background-color: ${(props) => (props.scrollTrackColor ? props.scrollTrackColor : '#ebebeb')};
    border-radius: ${(props) => (props.scrollRadius ? props.scrollRadius : '10px')};
  }

  ::-webkit-scrollbar-thumb {
    background: ${(props) => (props.scrollHandleColor ? props.scrollHandleColor : '#6d6d6d')};
    border-radius: ${(props) => (props.scrollRadius ? props.scrollRadius : '10px')};
  }
`
