import styled, { css } from 'styled-components'

const ANIMATION_DURATION = '0.3s'
const ANIMATION_FUNCTION = 'ease-in-out'

export const AnimateColors = css`
  transition:
    color ${ANIMATION_DURATION} ${ANIMATION_FUNCTION},
    background-color ${ANIMATION_DURATION} ${ANIMATION_FUNCTION},
    border-color ${ANIMATION_DURATION} ${ANIMATION_FUNCTION},
    outline-color ${ANIMATION_DURATION} ${ANIMATION_FUNCTION},
    stroke ${ANIMATION_DURATION} ${ANIMATION_FUNCTION},
    fill ${ANIMATION_DURATION} ${ANIMATION_FUNCTION};
`

export const AnimateAll = css`
  transition: all ${ANIMATION_DURATION} ${ANIMATION_FUNCTION};
`

export const HideOnMobile = css`
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    display: none;
  }
`

export const HideOnDesktop = css`
  @media (min-width: ${({ theme }) => theme.breakpoints.mobile + 1}px) {
    display: none;
  }
`

export const Spacer = styled.div<{
  margin?: string
  size?: number
  width?: string
  $desktopOnly?: boolean
  $mobileOnly?: boolean
}>`
  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}

  ${({ margin }) => css`
    margin: ${margin || '0'};
  `}

  ${({ size }) =>
    size &&
    css`
      height: ${size}px;
      min-height: ${size}px;
    `}

  ${({ $desktopOnly }) =>
    $desktopOnly &&
    css`
      @media (max-width: 700px) {
        display: none;
      }
    `}

    ${({ $mobileOnly }) =>
    $mobileOnly &&
    css`
      @media (min-width: 700px) {
        display: none;
      }
    `}
`

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100svh;
  padding: 32px;

  background-color: ${({ theme }) => theme.globals.bgColor};
  color: ${({ theme }) => theme.container.color};

  @media (max-width: 700px) {
    padding: 0px;
    background-color: ${({ theme }) => theme.container.bgColor};
  }
`

export const Container = styled.div`
  background-color: ${({ theme }) => theme.container.bgColor};
  width: ${({ theme }) => theme.container.width};
  padding: ${({ theme }) => theme.container.padding};
  border-radius: ${({ theme }) => theme.container.borderRadius};
  border: ${({ theme }) => theme.container.border};
  box-shadow: ${({ theme }) => theme.container.boxShadow};
  margin: ${({ theme }) => theme.container.margin};
  color: ${({ theme }) => theme.container.color};
  max-width: 578px;
  font-family: Inter;

  @media (max-width: 700px) {
    border: none;
    box-shadow: none;
  }

  @media (max-width: 700px) {
    padding: ${({ theme }) => theme.container.paddingMobile || '50px 32px'};
  }
`

export const ContentContainerBottomFixed = styled.div<{
  $mobileGap?: string
  $borderTop?: string
  $paddingMobile?: string
  $isDarkerBg?: boolean
}>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 10px;
  border-top: ${(props) => (props.$borderTop ? props.$borderTop : '1px solid #ebebeb')};
  padding-top: 30px;
  box-sizing: border-box;
  z-index: 1;

  @media (max-width: 700px) {
    background-color: ${(props) => (props.$isDarkerBg ? props.theme.globals.bgColor : '#fff')};
    position: fixed;
    padding: ${(props) => (props.$paddingMobile ? props.$paddingMobile : '30px 20px 20px')};
    margin-bottom: 0%;
    bottom: 0px;
    left: 0;
    width: 100%;
    gap: ${(props) => (props.$mobileGap ? props.$mobileGap : '0px')};
  }

  @media (max-height: 1024px) and (min-width: 780px) {
    padding-top: 8px;
    padding-bottom: 0px;
    margin: 0;
  }
`

export const CheckboxForm = styled.div.attrs({ className: 'checkbox-form' })`
  display: flex;
  width: 100%;
  margin: 10px 0 30px 0;

  input[type='checkbox'] {
    margin-right: 10px;
  }

  input:checked + .label-text:after {
    top: 0px;
  }

  @media (max-height: 1024px) and (min-width: 780px) {
    margin: 8px 0 16px 0;
  }
`

export const ButtonImgWrap = styled.div`
  margin-right: 5px;
`

export const CenteredContent = styled.div<{ margin?: string }>`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: ${(props) => (props.margin ? props.margin : '0')};
`

export const ContentToLeft = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

export const ProceedButton = styled.button<{ $disabled?: boolean }>`
  cursor: pointer;
  display: flex;
  width: 200px;
  height: 40px;
  min-width: 120px;
  padding: 0 16px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: #cf4500;
  outline: none;
  border: none;
  color: #fff;
  text-align: center;

  /* Paragraph 4 - Medium */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */

  align-self: flex-end;
  margin-left: auto;

  &:disabled {
    background-color: #acb0b5;
    cursor: not-allowed;
  }

  ${({ $disabled }) =>
    $disabled &&
    css`
      background-color: #acb0b5;
    `}

  transition: background-color 0.2s ease-in;
`

export const RequiredAsterisk = styled.span`
  color: red;
  font-size: inherit;
`
