import { Container } from '@/styles/generic.styles'
import { FC } from 'react'
import { LoadingSpinner } from '../LoadingSpinner'
import { useTheme } from 'styled-components'

export const Loading: FC = () => {
  const theme = useTheme()
  return (
    <Container>
      <LoadingSpinner {...theme.loadingSpinner} height="400px" />
    </Container>
  )
}
