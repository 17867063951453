import React from "react";

interface ArrowDownProps {
  color: string;
  thickness?: string;
}

export const ArrowDown = ({ color, thickness }: ArrowDownProps) => {
  return (
    <svg
      width="800px"
      height="800px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 18.5858L6.70711 14.2929C6.31658 13.9024 5.68342 13.9024 5.29289 14.2929C4.90237 14.6834 4.90237 15.3166 5.29289 15.7071L11.2929 21.7071C11.6834 22.0976 12.3166 22.0976 12.7071 21.7071L18.7071 15.7071C19.0976 15.3166 19.0976 14.6834 18.7071 14.2929C18.3166 13.9024 17.6834 13.9024 17.2929 14.2929L13 18.5858L13 3C13 2.44771 12.5523 2 12 2C11.4477 2 11 2.44771 11 3L11 18.5858Z"
        fill={`${color ? color : "#280b60"}`}
        stroke={`${color ? color : "#280b60"}`}
        strokeWidth={`${thickness ? thickness : "0"}`}
        strokeLinecap="round"
        className="colorized--fill colorized--stroke"
      />
    </svg>
  );
};
