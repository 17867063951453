export const countryList = [
  {
    value: 'AD',
    label: 'Andorra',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/1/19/Flag_of_Andorra.svg',
  },
  {
    value: 'AE',
    label: 'United Arab Emirates',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/c/cb/Flag_of_the_United_Arab_Emirates.svg',
  },
  {
    value: 'AF',
    label: 'Afghanistan',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_the_Taliban.svg',
  },
  {
    value: 'AG',
    label: 'Antigua and Barbuda',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/8/89/Flag_of_Antigua_and_Barbuda.svg',
  },
  {
    value: 'AI',
    label: 'Anguilla',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/b/b4/Flag_of_Anguilla.svg',
  },
  {
    value: 'AL',
    label: 'Albania',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/3/36/Flag_of_Albania.svg',
  },
  {
    value: 'AM',
    label: 'Armenia',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/2/2f/Flag_of_Armenia.svg',
  },
  {
    value: 'AO',
    label: 'Angola',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/9/9d/Flag_of_Angola.svg',
  },
  {
    value: 'AR',
    label: 'Argentina',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/1/1a/Flag_of_Argentina.svg',
  },
  {
    value: 'AS',
    label: 'American Samoa',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/8/87/Flag_of_American_Samoa.svg',
  },
  {
    value: 'AT',
    label: 'Austria',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/4/41/Flag_of_Austria.svg',
  },
  {
    value: 'AU',
    label: 'Australia',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/8/88/Flag_of_Australia_(converted).svg',
  },
  {
    value: 'AW',
    label: 'Aruba',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/f/f6/Flag_of_Aruba.svg',
  },
  {
    value: 'AX',
    label: 'Åland Islands',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/5/52/Flag_of_%C3%85land.svg',
  },
  {
    value: 'AZ',
    label: 'Azerbaijan',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/d/dd/Flag_of_Azerbaijan.svg',
  },
  {
    value: 'BA',
    label: 'Bosnia and Herzegovina',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/b/bf/Flag_of_Bosnia_and_Herzegovina.svg',
  },
  {
    value: 'BB',
    label: 'Barbados',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/e/ef/Flag_of_Barbados.svg',
  },
  {
    value: 'BD',
    label: 'Bangladesh',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/f/f9/Flag_of_Bangladesh.svg',
  },
  {
    value: 'BE',
    label: 'Belgium',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/6/65/Flag_of_Belgium.svg',
  },
  {
    value: 'BF',
    label: 'Burkina Faso',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/3/31/Flag_of_Burkina_Faso.svg',
  },
  {
    value: 'BG',
    label: 'Bulgaria',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/9/9a/Flag_of_Bulgaria.svg',
  },
  {
    value: 'BH',
    label: 'Bahrain',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/2/2c/Flag_of_Bahrain.svg',
  },
  {
    value: 'BI',
    label: 'Burundi',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/5/50/Flag_of_Burundi.svg',
  },
  {
    value: 'BJ',
    label: 'Benin',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/0/0a/Flag_of_Benin.svg',
  },
  {
    value: 'BL',
    label: 'Saint Barthélemy',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/0/03/Saint-Barthelémy_Icône.svg',
  },
  {
    value: 'BM',
    label: 'Bermuda',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/b/bf/Flag_of_Bermuda.svg',
  },
  {
    value: 'BN',
    label: 'Brunei Darussalam',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/9/9c/Flag_of_Brunei.svg',
  },
  {
    value: 'BO',
    label: 'Bolivia, Plurinational State of',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/5/5b/Bolivia_Flag.svg',
  },
  {
    value: 'BQ',
    label: 'Bonaire, Sint Eustatius and Saba',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/2/20/Flag_of_the_Netherlands.svg',
  },
  {
    value: 'BR',
    label: 'Brazil',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/0/05/Flag_of_Brazil.svg',
  },
  {
    value: 'BS',
    label: 'Bahamas',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/9/93/Flag_of_the_Bahamas.svg',
  },
  {
    value: 'BT',
    label: 'Bhutan',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/9/91/Flag_of_Bhutan.svg',
  },
  {
    value: 'BW',
    label: 'Botswana',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/f/fa/Flag_of_Botswana.svg',
  },
  {
    value: 'BY',
    label: 'Belarus',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/8/85/Flag_of_Belarus.svg',
  },
  {
    value: 'BZ',
    label: 'Belize',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/e/e7/Flag_of_Belize.svg',
  },
  {
    value: 'CA',
    label: 'Canada',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/d/d9/Flag_of_Canada_(Pantone).svg',
  },
  {
    value: 'CC',
    label: 'Cocos (Keeling) Islands',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/7/74/Flag_of_the_Cocos_(Keeling)_Islands.svg',
  },
  {
    value: 'CD',
    label: 'Congo, Democratic Republic of the',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/1/11/Flag_of_the_Democratic_Republic_of_the_Congo_(3-2).svg',
  },
  {
    value: 'CF',
    label: 'Central African Republic',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/6/6f/Flag_of_the_Central_African_Republic.svg',
  },
  {
    value: 'CG',
    label: 'Congo',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/9/92/Flag_of_the_Republic_of_the_Congo.svg',
  },
  {
    value: 'CH',
    label: 'Switzerland',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/f/f3/Flag_of_Switzerland.svg',
  },
  {
    value: 'CI',
    label: "Côte d'Ivoire",
    icon: 'https://upload.wikimedia.org/wikipedia/commons/f/fe/Flag_of_Côte_d%27Ivoire.svg',
  },
  {
    value: 'CK',
    label: 'Cook Islands',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/3/35/Flag_of_the_Cook_Islands.svg',
  },
  {
    value: 'CL',
    label: 'Chile',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/7/78/Flag_of_Chile.svg',
  },
  {
    value: 'CM',
    label: 'Cameroon',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/4/4f/Flag_of_Cameroon.svg',
  },
  {
    value: 'CN',
    label: 'China',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/f/fa/Flag_of_the_People%27s_Republic_of_China.svg',
  },
  {
    value: 'CO',
    label: 'Colombia',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/2/21/Flag_of_Colombia.svg',
  },
  {
    value: 'CR',
    label: 'Costa Rica',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Costa_Rica_(state).svg',
  },
  {
    value: 'CU',
    label: 'Cuba',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/b/bd/Flag_of_Cuba.svg',
  },
  {
    value: 'CV',
    label: 'Cabo Verde',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/3/38/Flag_of_Cape_Verde.svg',
  },
  {
    value: 'CW',
    label: 'Curaçao',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/b/b1/Flag_of_Curaçao.svg',
  },
  {
    value: 'CX',
    label: 'Christmas Island',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/6/67/Flag_of_Christmas_Island.svg',
  },
  {
    value: 'CY',
    label: 'Cyprus',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Cyprus.svg',
  },
  {
    value: 'CZ',
    label: 'Czechia',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/c/cb/Flag_of_the_Czech_Republic.svg',
  },
  {
    value: 'DE',
    label: 'Germany',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/b/ba/Flag_of_Germany.svg',
  },
  {
    value: 'DJ',
    label: 'Djibouti',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/3/34/Flag_of_Djibouti.svg',
  },
  {
    value: 'DK',
    label: 'Denmark',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/9/9c/Flag_of_Denmark.svg',
  },
  {
    value: 'DM',
    label: 'Dominica',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/c/c4/Flag_of_Dominica.svg',
  },
  {
    value: 'DO',
    label: 'Dominican Republic',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/9/9f/Flag_of_the_Dominican_Republic.svg',
  },
  {
    value: 'DZ',
    label: 'Algeria',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/7/77/Flag_of_Algeria.svg',
  },
  {
    value: 'EC',
    label: 'Ecuador',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/e/e8/Flag_of_Ecuador.svg',
  },
  {
    value: 'EE',
    label: 'Estonia',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/8/8f/Flag_of_Estonia.svg',
  },
  {
    value: 'EG',
    label: 'Egypt',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/f/fe/Flag_of_Egypt.svg',
  },
  {
    value: 'EH',
    label: 'Western Sahara',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/2/26/Flag_of_the_Sahrawi_Arab_Democratic_Republic.svg',
  },
  {
    value: 'ER',
    label: 'Eritrea',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/2/29/Flag_of_Eritrea.svg',
  },
  {
    value: 'ES',
    label: 'Spain',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/9/9a/Flag_of_Spain.svg',
  },
  {
    value: 'ET',
    label: 'Ethiopia',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/7/71/Flag_of_Ethiopia.svg',
  },
  {
    value: 'FI',
    label: 'Finland',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Finland.svg',
  },
  {
    value: 'FJ',
    label: 'Fiji',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/b/ba/Flag_of_Fiji.svg',
  },
  {
    value: 'FK',
    label: 'Falkland Islands (Malvinas)',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/8/83/Flag_of_the_Falkland_Islands.svg',
  },
  {
    value: 'FM',
    label: 'Micronesia, Federated States of',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/e/e4/Flag_of_the_Federated_States_of_Micronesia.svg',
  },
  {
    value: 'FO',
    label: 'Faroe Islands',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/3/3c/Flag_of_the_Faroe_Islands.svg',
  },
  {
    value: 'FR',
    label: 'France',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/c/c3/Flag_of_France.svg',
  },
  {
    value: 'GA',
    label: 'Gabon',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/0/04/Flag_of_Gabon.svg',
  },
  {
    value: 'GB',
    label: 'United Kingdom of Great Britain and Northern Ireland',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/a/a5/Flag_of_the_United_Kingdom_(1-2).svg',
  },
  {
    value: 'GD',
    label: 'Grenada',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Grenada.svg',
  },
  {
    value: 'GE',
    label: 'Georgia',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/0/0f/Flag_of_Georgia.svg',
  },
  {
    value: 'GF',
    label: 'French Guiana',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/e/ed/Flag_of_France_%28Pantone%29.svg',
  },
  {
    value: 'GG',
    label: 'Guernsey',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/f/fa/Flag_of_Guernsey.svg',
  },
  {
    value: 'GH',
    label: 'Ghana',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/1/19/Flag_of_Ghana.svg',
  },
  {
    value: 'GI',
    label: 'Gibraltar',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/0/02/Flag_of_Gibraltar.svg',
  },
  {
    value: 'GL',
    label: 'Greenland',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/0/09/Flag_of_Greenland.svg',
  },
  {
    value: 'GM',
    label: 'Gambia',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/7/77/Flag_of_The_Gambia.svg',
  },
  {
    value: 'GN',
    label: 'Guinea',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/e/ed/Flag_of_Guinea.svg',
  },
  {
    value: 'GP',
    label: 'Guadeloupe',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/9/9f/Flag_of_France_%287x10%29.svg',
  },
  {
    value: 'GQ',
    label: 'Equatorial Guinea',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/3/31/Flag_of_Equatorial_Guinea.svg',
  },
  {
    value: 'GR',
    label: 'Greece',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_Greece.svg',
  },
  {
    value: 'GS',
    label: 'South Georgia and the South Sandwich Islands',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/e/ed/Flag_of_South_Georgia_and_the_South_Sandwich_Islands.svg',
  },
  {
    value: 'GT',
    label: 'Guatemala',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/e/ec/Flag_of_Guatemala.svg',
  },
  {
    value: 'GU',
    label: 'Guam',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/0/07/Flag_of_Guam.svg',
  },
  {
    value: 'GW',
    label: 'Guinea-Bissau',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/0/01/Flag_of_Guinea-Bissau.svg',
  },
  {
    value: 'GY',
    label: 'Guyana',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/9/99/Flag_of_Guyana.svg',
  },
  {
    value: 'HK',
    label: 'Hong Kong',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/5/5b/Flag_of_Hong_Kong.svg',
  },
  {
    value: 'HN',
    label: 'Honduras',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/8/82/Flag_of_Honduras.svg',
  },
  {
    value: 'HR',
    label: 'Croatia',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/1/1b/Flag_of_Croatia.svg',
  },
  {
    value: 'HT',
    label: 'Haiti',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/5/56/Flag_of_Haiti.svg',
  },
  {
    value: 'HU',
    label: 'Hungary',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/c/c1/Flag_of_Hungary.svg',
  },
  {
    value: 'ID',
    label: 'Indonesia',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/9/9f/Flag_of_Indonesia.svg',
  },
  {
    value: 'IE',
    label: 'Ireland',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/4/45/Flag_of_Ireland.svg',
  },
  {
    value: 'IL',
    label: 'Israel',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Israel.svg',
  },
  {
    value: 'IM',
    label: 'Isle of Man',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_the_Isle_of_Man.svg',
  },
  {
    value: 'IN',
    label: 'India',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/4/41/Flag_of_India.svg',
  },
  {
    value: 'IQ',
    label: 'Iraq',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/f/f6/Flag_of_Iraq.svg',
  },
  {
    value: 'IR',
    label: 'Iran, Islamic Republic of',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/c/ca/Flag_of_Iran.svg',
  },
  {
    value: 'IS',
    label: 'Iceland',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/c/ce/Flag_of_Iceland.svg',
  },
  {
    value: 'IT',
    label: 'Italy',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/0/03/Flag_of_Italy.svg',
  },
  {
    value: 'JE',
    label: 'Jersey',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/1/1c/Flag_of_Jersey.svg',
  },
  {
    value: 'JM',
    label: 'Jamaica',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/0/0a/Flag_of_Jamaica.svg',
  },
  {
    value: 'JO',
    label: 'Jordan',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/c/c0/Flag_of_Jordan.svg',
  },
  {
    value: 'JP',
    label: 'Japan',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Japan%28bordered%29.svg',
  },
  {
    value: 'KE',
    label: 'Kenya',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/4/49/Flag_of_Kenya.svg',
  },
  {
    value: 'KG',
    label: 'Kyrgyzstan',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/c/c7/Flag_of_Kyrgyzstan.svg',
  },
  {
    value: 'KH',
    label: 'Cambodia',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/8/83/Flag_of_Cambodia.svg',
  },
  {
    value: 'KI',
    label: 'Kiribati',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/d/d3/Flag_of_Kiribati.svg',
  },
  {
    value: 'KM',
    label: 'Comoros',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/d/df/Flag_of_the_Comoros_(3-2).svg',
  },
  {
    value: 'KN',
    label: 'Saint Kitts and Nevis',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/f/fe/Flag_of_Saint_Kitts_and_Nevis.svg',
  },
  {
    value: 'KP',
    label: "Korea, Democratic People's Republic of",
    icon: 'https://upload.wikimedia.org/wikipedia/commons/5/51/Flag_of_North_Korea.svg',
  },
  {
    value: 'KR',
    label: 'Korea, Republic of',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/0/09/Flag_of_South_Korea.svg',
  },
  {
    value: 'KW',
    label: 'Kuwait',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/a/aa/Flag_of_Kuwait.svg',
  },
  {
    value: 'KY',
    label: 'Cayman Islands',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/0/0f/Flag_of_the_Cayman_Islands.svg',
  },
  {
    value: 'KZ',
    label: 'Kazakhstan',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/d/d3/Flag_of_Kazakhstan.svg',
  },
  {
    value: 'LA',
    label: "Lao People's Democratic Republic",
    icon: 'https://upload.wikimedia.org/wikipedia/commons/5/56/Flag_of_Laos.svg',
  },
  {
    value: 'LB',
    label: 'Lebanon',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/5/59/Flag_of_Lebanon.svg',
  },
  {
    value: 'LC',
    label: 'Saint Lucia',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/9/9f/Flag_of_Saint_Lucia.svg',
  },
  {
    value: 'LI',
    label: 'Liechtenstein',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/4/47/Flag_of_Liechtenstein.svg',
  },
  {
    value: 'LK',
    label: 'Sri Lanka',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/1/11/Flag_of_Sri_Lanka.svg',
  },
  {
    value: 'LR',
    label: 'Liberia',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/b/b8/Flag_of_Liberia.svg',
  },
  {
    value: 'LS',
    label: 'Lesotho',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/4/4a/Flag_of_Lesotho.svg',
  },
  {
    value: 'LT',
    label: 'Lithuania',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/1/11/Flag_of_Lithuania.svg',
  },
  {
    value: 'LU',
    label: 'Luxembourg',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/d/da/Flag_of_Luxembourg.svg',
  },
  {
    value: 'LV',
    label: 'Latvia',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/8/84/Flag_of_Latvia.svg',
  },
  {
    value: 'LY',
    label: 'Libya',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/0/05/Flag_of_Libya.svg',
  },
  {
    value: 'MA',
    label: 'Morocco',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/2/2c/Flag_of_Morocco.svg',
  },
  {
    value: 'MC',
    label: 'Monaco',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/e/ea/Flag_of_Monaco.svg',
  },
  {
    value: 'MD',
    label: 'Moldova, Republic of',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/2/27/Flag_of_Moldova.svg',
  },
  {
    value: 'ME',
    label: 'Montenegro',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/6/64/Flag_of_Montenegro.svg',
  },
  {
    value: 'MF',
    label: 'Saint Martin, (French part)',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/d/dd/Flag_of_Saint-Martin_%28fictional%29.svg',
  },
  {
    value: 'MG',
    label: 'Madagascar',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Madagascar.svg',
  },
  {
    value: 'MH',
    label: 'Marshall Islands',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/2/2e/Flag_of_the_Marshall_Islands.svg',
  },
  {
    value: 'MK',
    label: 'North Macedonia',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/7/79/Flag_of_North_Macedonia.svg',
  },
  {
    value: 'ML',
    label: 'Mali',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/9/92/Flag_of_Mali.svg',
  },
  {
    value: 'MM',
    label: 'Myanmar',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/8/8c/Flag_of_Myanmar.svg',
  },
  {
    value: 'MN',
    label: 'Mongolia',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/4/4c/Flag_of_Mongolia.svg',
  },
  {
    value: 'MO',
    label: 'Macao',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/6/63/Flag_of_Macau.svg',
  },
  {
    value: 'MP',
    label: 'Northern Mariana Islands',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/e/e0/Flag_of_the_Northern_Mariana_Islands.svg',
  },
  {
    value: 'MQ',
    label: 'Martinique',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/2/21/Flag_of_the_Territorial_Collectivity_of_Martinique.svg',
  },
  {
    value: 'MR',
    label: 'Mauritania',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/4/43/Flag_of_Mauritania.svg',
  },
  {
    value: 'MS',
    label: 'Montserrat',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/d/d0/Flag_of_Montserrat.svg',
  },
  {
    value: 'MT',
    label: 'Malta',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/7/73/Flag_of_Malta.svg',
  },
  {
    value: 'MU',
    label: 'Mauritius',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/7/77/Flag_of_Mauritius.svg',
  },
  {
    value: 'MV',
    label: 'Maldives',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/0/0f/Flag_of_Maldives.svg',
  },
  {
    value: 'MW',
    label: 'Malawi',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/d/d1/Flag_of_Malawi.svg',
  },
  {
    value: 'MX',
    label: 'Mexico',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/f/fc/Flag_of_Mexico.svg',
  },
  {
    value: 'MY',
    label: 'Malaysia',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/6/66/Flag_of_Malaysia.svg',
  },
  {
    value: 'MZ',
    label: 'Mozambique',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/d/d0/Flag_of_Mozambique.svg',
  },
  {
    value: 'NA',
    label: 'Namibia',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/0/00/Flag_of_Namibia.svg',
  },
  {
    value: 'NC',
    label: 'New Caledonia',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/6/66/Flag_of_FLNKS.svg',
  },
  {
    value: 'NE',
    label: 'Niger',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/f/f4/Flag_of_Niger.svg',
  },
  {
    value: 'NF',
    label: 'Norfolk Island',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/4/48/Flag_of_Norfolk_Island.svg',
  },
  {
    value: 'NG',
    label: 'Nigeria',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/7/79/Flag_of_Nigeria.svg',
  },
  {
    value: 'NI',
    label: 'Nicaragua',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/1/19/Flag_of_Nicaragua.svg',
  },
  {
    value: 'NL',
    label: 'Netherlands',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/2/20/Flag_of_the_Netherlands.svg',
  },
  {
    value: 'NO',
    label: 'Norway',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/d/d9/Flag_of_Norway.svg',
  },
  {
    value: 'NP',
    label: 'Nepal',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/9/9b/Flag_of_Nepal.svg',
  },
  {
    value: 'NR',
    label: 'Nauru',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/3/30/Flag_of_Nauru.svg',
  },
  {
    value: 'NU',
    label: 'Niue',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/0/01/Flag_of_Niue.svg',
  },
  {
    value: 'NZ',
    label: 'New Zealand',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/3/3e/Flag_of_New_Zealand.svg',
  },
  {
    value: 'OM',
    label: 'Oman',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/d/dd/Flag_of_Oman.svg',
  },
  {
    value: 'PA',
    label: 'Panama',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/a/ab/Flag_of_Panama.svg',
  },
  {
    value: 'PE',
    label: 'Peru',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/c/cf/Flag_of_Peru.svg',
  },
  {
    value: 'PF',
    label: 'French Polynesia',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/d/db/Flag_of_French_Polynesia.svg',
  },
  {
    value: 'PG',
    label: 'Papua New Guinea',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/e/e3/Flag_of_Papua_New_Guinea.svg',
  },
  {
    value: 'PH',
    label: 'Philippines',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/9/99/Flag_of_the_Philippines.svg',
  },
  {
    value: 'PK',
    label: 'Pakistan',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/3/32/Flag_of_Pakistan.svg',
  },
  {
    value: 'PL',
    label: 'Poland',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/1/12/Flag_of_Poland.svg',
  },
  {
    value: 'PM',
    label: 'Saint Pierre and Miquelon',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/7/74/Flag_of_Saint-Pierre_and_Miquelon.svg',
  },
  {
    value: 'PN',
    label: 'Pitcairn',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/8/88/Flag_of_the_Pitcairn_Islands.svg',
  },
  {
    value: 'PR',
    label: 'Puerto Rico',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/2/28/Flag_of_Puerto_Rico.svg',
  },
  {
    value: 'PS',
    label: 'Palestine, State of',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/f/f4/Palestine_Flag.svg',
  },
  {
    value: 'PT',
    label: 'Portugal',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_Portugal.svg',
  },
  {
    value: 'PW',
    label: 'Palau',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/4/48/Flag_of_Palau.svg',
  },
  {
    value: 'PY',
    label: 'Paraguay',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/2/27/Flag_of_Paraguay.svg',
  },
  {
    value: 'QA',
    label: 'Qatar',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/6/65/Flag_of_Qatar.svg',
  },
  {
    value: 'RO',
    label: 'Romania',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/7/73/Flag_of_Romania.svg',
  },
  {
    value: 'RS',
    label: 'Serbia',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/f/ff/Flag_of_Serbia.svg',
  },
  {
    value: 'RU',
    label: 'Russian Federation',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/f/f3/Flag_of_Russia.svg',
  },
  {
    value: 'RW',
    label: 'Rwanda',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/1/17/Flag_of_Rwanda.svg',
  },
  {
    value: 'SA',
    label: 'Saudi Arabia',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/0/0d/Flag_of_Saudi_Arabia.svg',
  },
  {
    value: 'SB',
    label: 'Solomon Islands',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/7/74/Flag_of_the_Solomon_Islands.svg',
  },
  {
    value: 'SC',
    label: 'Seychelles',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/f/fc/Flag_of_Seychelles.svg',
  },
  {
    value: 'SD',
    label: 'Sudan',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/0/01/Flag_of_Sudan.svg',
  },
  {
    value: 'SE',
    label: 'Sweden',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/4/4c/Flag_of_Sweden.svg',
  },
  {
    value: 'SG',
    label: 'Singapore',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/4/48/Flag_of_Singapore.svg',
  },
  {
    value: 'SH',
    label: 'Saint Helena, Ascension and Tristan da Cunha',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/0/00/Flag_of_Saint_Helena.svg',
  },
  {
    value: 'SI',
    label: 'Slovenia',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/f/f0/Flag_of_Slovenia.svg',
  },
  {
    value: 'SJ',
    label: 'Svalbard and Jan Mayen',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/d/d9/Flag_of_Norway.svg',
  },
  {
    value: 'SK',
    label: 'Slovakia',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/e/e6/Flag_of_Slovakia.svg',
  },
  {
    value: 'SL',
    label: 'Sierra Leone',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/1/17/Flag_of_Sierra_Leone.svg',
  },
  {
    value: 'SM',
    label: 'San Marino',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/b/b1/Flag_of_San_Marino.svg',
  },
  {
    value: 'SN',
    label: 'Senegal',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/f/fd/Flag_of_Senegal.svg',
  },
  {
    value: 'SO',
    label: 'Somalia',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/a/a0/Flag_of_Somalia.svg',
  },
  {
    value: 'SR',
    label: 'Suriname',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/6/60/Flag_of_Suriname.svg',
  },
  {
    value: 'SS',
    label: 'South Sudan',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/7/7a/Flag_of_South_Sudan.svg',
  },
  {
    value: 'SV',
    label: 'El Salvador',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/3/34/Flag_of_El_Salvador.svg',
  },
  {
    value: 'SX',
    label: 'Sint Maarten, (Dutch part)',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/d/d3/Flag_of_Sint_Maarten.svg',
  },
  {
    value: 'SY',
    label: 'Syrian Arab Republic',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/5/53/Flag_of_Syria.svg',
  },
  {
    value: 'SZ',
    label: 'Eswatini',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/f/fb/Flag_of_Eswatini.svg',
  },
  {
    value: 'TC',
    label: 'Turks and Caicos Islands',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/a/a0/Flag_of_the_Turks_and_Caicos_Islands.svg',
  },
  {
    value: 'TD',
    label: 'Chad',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/4/4b/Flag_of_Chad.svg',
  },
  {
    value: 'TG',
    label: 'Togo',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/6/68/Flag_of_Togo.svg',
  },
  {
    value: 'TH',
    label: 'Thailand',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/a/a9/Flag_of_Thailand.svg',
  },
  {
    value: 'TJ',
    label: 'Tajikistan',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/d/d0/Flag_of_Tajikistan.svg',
  },
  {
    value: 'TK',
    label: 'Tokelau',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/8/8e/Flag_of_Tokelau.svg',
  },
  {
    value: 'TL',
    label: 'Timor-Leste',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/2/26/Flag_of_East_Timor.svg',
  },
  {
    value: 'TM',
    label: 'Turkmenistan',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/1/1b/Flag_of_Turkmenistan.svg',
  },
  {
    value: 'TN',
    label: 'Tunisia',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/c/ce/Flag_of_Tunisia.svg',
  },
  {
    value: 'TO',
    label: 'Tonga',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/9/9a/Flag_of_Tonga.svg',
  },
  {
    value: 'TR',
    label: 'Türkiye',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/b/b4/Flag_of_Turkey.svg',
  },
  {
    value: 'TT',
    label: 'Trinidad and Tobago',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/6/64/Flag_of_Trinidad_and_Tobago.svg',
  },
  {
    value: 'TV',
    label: 'Tuvalu',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/3/38/Flag_of_Tuvalu.svg',
  },
  {
    value: 'TW',
    label: 'Taiwan',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/7/72/Flag_of_the_Republic_of_China.svg',
  },
  {
    value: 'TZ',
    label: 'Tanzania, United Republic of',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/3/38/Flag_of_Tanzania.svg',
  },
  {
    value: 'UA',
    label: 'Ukraine',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/4/49/Flag_of_Ukraine.svg',
  },
  {
    value: 'UG',
    label: 'Uganda',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/4/4e/Flag_of_Uganda.svg',
  },
  {
    value: 'UM',
    label: 'United States Minor Outlying Islands',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/0/05/Flag_of_the_U.S..svg',
  },
  {
    value: 'US',
    label: 'United States of America',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/a/a4/Flag_of_the_United_States.svg',
  },
  {
    value: 'UY',
    label: 'Uruguay',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/f/fe/Flag_of_Uruguay.svg',
  },
  {
    value: 'UZ',
    label: 'Uzbekistan',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/8/84/Flag_of_Uzbekistan.svg',
  },
  {
    value: 'VA',
    label: 'Holy See',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/0/00/Flag_of_the_Vatican_City.svg',
  },
  {
    value: 'VC',
    label: 'Saint Vincent and the Grenadines',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/6/6d/Flag_of_Saint_Vincent_and_the_Grenadines.svg',
  },
  {
    value: 'VE',
    label: 'Venezuela, Bolivarian Republic of',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/7/7b/Flag_of_Venezuela_(state).svg',
  },
  {
    value: 'VG',
    label: 'Virgin Islands, British',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/4/42/Flag_of_the_British_Virgin_Islands.svg',
  },
  {
    value: 'VI',
    label: 'Virgin Islands, U.S.',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/f/f8/Flag_of_the_United_States_Virgin_Islands.svg',
  },
  {
    value: 'VN',
    label: 'Viet Nam',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/2/21/Flag_of_Vietnam.svg',
  },
  {
    value: 'VU',
    label: 'Vanuatu',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/6/6e/Flag_of_Vanuatu_(official).svg',
  },
  {
    value: 'WF',
    label: 'Wallis and Futuna',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/d/d2/Flag_of_Wallis_and_Futuna.svg',
  },
  {
    value: 'WS',
    label: 'Samoa',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/3/31/Flag_of_Samoa.svg',
  },
  {
    value: 'YE',
    label: 'Yemen',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/8/89/Flag_of_Yemen.svg',
  },
  {
    value: 'YT',
    label: 'Mayotte',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/c/c3/Flag_of_France.svg',
  },
  {
    value: 'ZA',
    label: 'South Africa',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/a/af/Flag_of_South_Africa.svg',
  },
  {
    value: 'ZM',
    label: 'Zambia',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/0/06/Flag_of_Zambia.svg',
  },
  {
    value: 'ZW',
    label: 'Zimbabwe',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/6/6a/Flag_of_Zimbabwe.svg',
  },
]
