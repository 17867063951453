import React from "react";

interface RefreshProps {
  color: string;
  thickness?: string;
}

export const Refresh = ({ color, thickness }: RefreshProps) => {
  return (
    <svg
      width="800px"
      height="800px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.06083 10.8901C2.61366 5.88912 6.85189 2 11.9998 2C14.2363 2 16.0401 2.88495 17.5809 4.0323C18.4274 4.66267 19.235 5.40719 19.9999 6.14376V3C19.9999 2.44771 20.4476 2 20.9999 2C21.5522 2 21.9999 2.44771 21.9999 3V8.5C21.9999 8.6503 21.9667 8.79285 21.9073 8.92074C21.8785 8.98289 21.8429 9.04275 21.8005 9.09924C21.7677 9.14307 21.7313 9.18412 21.6918 9.22197C21.607 9.30337 21.5111 9.36702 21.409 9.41276C21.2841 9.46882 21.1456 9.5 20.9999 9.5H15.4999C14.9476 9.5 14.4999 9.05228 14.4999 8.5C14.4999 7.94772 14.9476 7.5 15.4999 7.5H18.5242C17.8279 6.82979 17.137 6.19538 16.3863 5.6364C15.0539 4.64418 13.6619 4 11.9998 4C7.88269 4 4.49078 7.11098 4.04872 11.1099C3.98804 11.6588 3.49384 12.0546 2.9449 11.9939C2.39596 11.9333 2.00015 11.4391 2.06083 10.8901Z"
        fill={`${color ? color : "#280b60"}`}
        stroke={`${color ? color : "#280b60"}`}
        strokeWidth={`${thickness ? thickness : "0"}`}
        strokeLinecap="round"
        className="colorized--fill colorized--stroke"
      />
      <path
        d="M21.9937 13.1099C21.4409 18.1109 17.2027 22 12.0547 22C9.81822 22 8.01448 21.115 6.47371 19.9677C5.62719 19.3373 4.81957 18.5928 4.05469 17.8562V21C4.05469 21.5523 3.60697 22 3.05469 22C2.5024 22 2.05469 21.5523 2.05469 21V15.5C2.05469 15.3497 2.08785 15.2071 2.14724 15.0793C2.17607 15.0171 2.21166 14.9572 2.25404 14.9008C2.2869 14.8569 2.32328 14.8159 2.36276 14.778C2.44755 14.6966 2.54344 14.633 2.64559 14.5872C2.77047 14.5312 2.90894 14.5 3.05469 14.5H8.55469C9.10697 14.5 9.55469 14.9477 9.55469 15.5C9.55469 16.0523 9.10697 16.5 8.55469 16.5H5.53039C6.22662 17.1702 6.91757 17.8046 7.66822 18.3636C9.00068 19.3558 10.3927 20 12.0547 20C16.1719 20 19.5638 16.889 20.0058 12.8901C20.0665 12.3412 20.5607 11.9454 21.1097 12.0061C21.6586 12.0667 22.0544 12.5609 21.9937 13.1099Z"
        fill={`${color ? color : "#280b60"}`}
        stroke={`${color ? color : "#280b60"}`}
        strokeWidth={`${thickness ? thickness : "0"}`}
        strokeLinecap="round"
        className="colorized--fill colorized--stroke"
      />
    </svg>
  );
};
