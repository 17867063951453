import { StoreContext } from '@/components/App'
import { Matrix } from '@/components/ProcessSteps/Matrix'
import { SignicatBranding } from '@/components/SignicatBranding/SignicatBranding'
import { DEV_MODE } from '@/constants/envs'
import { axiosInstance } from '@/methods/axiosConfig'
import { Page } from '@/styles/generic.styles'
import { observer } from 'mobx-react'
import { FC, useContext, useEffect } from 'react'

export const InitProcessPage: FC = observer(() => {
  const store = useContext(StoreContext)
  const { step } = store.AppState

  const initProcess = async () => {
    store.AppState.setStep('init')
    console.log('initProcess')

    try {
      if (DEV_MODE) return
      const { data } = await axiosInstance.get('/api/pdf-document')
      console.log(data)

      store.AppState.setPdfBase64(data)
      store.AppState.setStep('pdf')
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    void initProcess()
  }, [])

  return (
    <Page>
      <Matrix step={step} />
      <SignicatBranding width={step === 'pdf' ? '980px' : undefined} />
    </Page>
  )
})
