import React from "react";
import { Container } from "./Icon.styles";

import { AlertTriangle } from "./icons/wolfround/alertTriangle";
import { ArrowDown } from "./icons/wolfround/arrowDown";
import { ArrowLeft } from "./icons/wolfround/arrowLeft";
import { ArrowRight } from "./icons/wolfround/arrowRight";
import { ArrowUp } from "./icons/wolfround/arrowUp";
import { AtSign } from "./icons/wolfround/atSign";
import { Camera } from "./icons/wolfround/camera";
import { CaretDown } from "./icons/wolfround/caretDown";
import { CaretLeft } from "./icons/wolfround/caretLeft";
import { CaretRight } from "./icons/wolfround/caretRight";
import { CaretUp } from "./icons/wolfround/caretUp";
import { Chain } from "./icons/wolfround/chain";
import { Checkmark } from "./icons/wolfround/checkmark";
import { Check } from "./icons/wolfround/check";
import { CheckmarkCircle } from "./icons/wolfround/checkmarkCircle";
import { DeleteLeft } from "./icons/wolfround/deleteLeft";
import { DeviceDesktop } from "./icons/wolfround/deviceDesktop";
import { DeviceMobile } from "./icons/wolfround/deviceMobile";
import { Dot } from "./icons/wolfround/dot";
import { Download } from "./icons/wolfround/download";
import { EmojiSmile } from "./icons/wolfround/emoji-smile";
import { Envelope } from "./icons/wolfround/envelope";
import { ExternalLink } from "./icons/wolfround/externalLink";
import { Fullscreen } from "./icons/wolfround/fullscreen";
import { ImageType } from "./icons/wolfround/imageType";
import { InfoCircle } from "./icons/wolfround/infoCircle";
import { Key } from "./icons/wolfround/key";
import { LockClosed } from "./icons/wolfround/lockClosed";
import { LockOpen } from "./icons/wolfround/lockOpen";
import { MapPin } from "./icons/wolfround/mapPin";
import { MessageBubble } from "./icons/wolfround/messageBubble";
import { Minus } from "./icons/wolfround/minus";
import { MinusSquare } from "./icons/wolfround/minusSquare";
import { NoSign } from "./icons/wolfround/noSign";
import { PaperPlane } from "./icons/wolfround/paperPlane";
import { Pen } from "./icons/wolfround/pen";
import { Pencil } from "./icons/wolfround/pencil";
import { Phone } from "./icons/wolfround/phone";
import { Plus } from "./icons/wolfround/plus";
import { PlusSquare } from "./icons/wolfround/plusSquare";
import { Printer } from "./icons/wolfround/printer";
import { Refresh } from "./icons/wolfround/refresh";
import { Search } from "./icons/wolfround/search";
import { TurnLeft } from "./icons/wolfround/turnLeft";
import { TurnRight } from "./icons/wolfround/turnRight";
import { Video } from "./icons/wolfround/video";
import { Xmark } from "./icons/wolfround/xmark";
import { XmarkCircle } from "./icons/wolfround/xmarkCircle";
import { DotsHorizontal } from "./icons/wolfround/dotsHorizontal";
import { HamburgerMenu } from "./icons/wolfround/hamburgerMenu";
import { User } from "./icons/wolfround/user";
import { Edit } from "./icons/wolfround/edit";

export const IconTypes = [
  "alert-triangle",
  "refresh",
  "arrow-down",
  "arrow-left",
  "arrow-right",
  "arrow-up",
  "camera",
  "caret-down",
  "caret-left",
  "caret-right",
  "caret-up",
  "check",
  "checkmark-circle",
  "delete-left",
  "device-desktop",
  "device-mobile",
  "dot",
  "emoji-smile",
  "envelope",
  "external-link",
  "fullscreen",
  "info-circle",
  "chain",
  "lock-open",
  "lock-closed",
  "message-bubble",
  "minus-square",
  "minus",
  "phone",
  "plus-square",
  "plus",
  "search",
  "x-mark-circle",
  "x-mark",
  "checkmark",
  "turn-left",
  "turn-right",
  "at-sign",
  "image-type",
  "key",
  "map-pin",
  "no-sign",
  "pen",
  "pencil",
  "paper-plane",
  "printer",
  "video",
  "download",
  "dots-horizontal",
  "hamburger-menu",
  "user",
];

export interface IconProps {
  [x: string]: any;
  size: string;
  type:
    | "alert-triangle"
    | "refresh"
    | "arrow-down"
    | "arrow-left"
    | "arrow-right"
    | "arrow-up"
    | "camera"
    | "caret-down"
    | "caret-left"
    | "caret-right"
    | "caret-up"
    | "check"
    | "checkmark-circle"
    | "delete-left"
    | "device-desktop"
    | "device-mobile"
    | "dot"
    | "edit"
    | "emoji-smile"
    | "envelope"
    | "external-link"
    | "fullscreen"
    | "info-circle"
    | "chain"
    | "lock-open"
    | "lock-closed"
    | "message-bubble"
    | "minus-square"
    | "minus"
    | "phone"
    | "plus-square"
    | "plus"
    | "search"
    | "x-mark-circle"
    | "x-mark"
    | "checkmark"
    | "turn-left"
    | "turn-right"
    | "at-sign"
    | "image-type"
    | "key"
    | "map-pin"
    | "no-sign"
    | "pen"
    | "pencil"
    | "paper-plane"
    | "printer"
    | "video"
    | "download"
    | "dots-horizontal"
    | "hamburger-menu"
    | "user";
  color?: string;
  colorHover?: string;
  thickness?: string;
}

export const Icon = ({
  size,
  type,
  color,
  colorHover,
  thickness,
}: IconProps) => {
  function renderIcon() {
    switch (type) {
      case "alert-triangle":
        return <AlertTriangle color={color} thickness={thickness} />;
      case "refresh":
        return <Refresh color={color} thickness={thickness} />;
      case "arrow-down":
        return <ArrowDown color={color} thickness={thickness} />;
      case "arrow-left":
        return <ArrowLeft color={color} thickness={thickness} />;
      case "arrow-right":
        return <ArrowRight color={color} thickness={thickness} />;
      case "arrow-up":
        return <ArrowUp color={color} thickness={thickness} />;
      case "camera":
        return <Camera color={color} thickness={thickness} />;
      case "caret-down":
        return <CaretDown color={color} thickness={thickness} />;
      case "caret-left":
        return <CaretLeft color={color} thickness={thickness} />;
      case "caret-right":
        return <CaretRight color={color} thickness={thickness} />;
      case "caret-up":
        return <CaretUp color={color} thickness={thickness} />;
      case "check":
        return <Check color={color} thickness={thickness} />;
      case "checkmark-circle":
        return <CheckmarkCircle color={color} thickness={thickness} />;
      case "delete-left":
        return <DeleteLeft color={color} thickness={thickness} />;
      case "device-desktop":
        return <DeviceDesktop color={color} thickness={thickness} />;
      case "device-mobile":
        return <DeviceMobile color={color} thickness={thickness} />;
      case "dot":
        return <Dot color={color} thickness={thickness} />;
      case "edit":
        return <Edit color={color} thickness={thickness} />;
      case "emoji-smile":
        return <EmojiSmile color={color} thickness={thickness} />;
      case "envelope":
        return <Envelope color={color} thickness={thickness} />;
      case "external-link":
        return <ExternalLink color={color} thickness={thickness} />;
      case "fullscreen":
        return <Fullscreen color={color} thickness={thickness} />;
      case "info-circle":
        return <InfoCircle color={color} thickness={thickness} />;
      case "chain":
        return <Chain color={color} thickness={thickness} />;
      case "lock-open":
        return <LockOpen color={color} thickness={thickness} />;
      case "lock-closed":
        return <LockClosed color={color} thickness={thickness} />;
      case "message-bubble":
        return <MessageBubble color={color} thickness={thickness} />;
      case "minus-square":
        return <MinusSquare color={color} thickness={thickness} />;
      case "minus":
        return <Minus color={color} thickness={thickness} />;
      case "phone":
        return <Phone color={color} thickness={thickness} />;
      case "plus-square":
        return <PlusSquare color={color} thickness={thickness} />;
      case "plus":
        return <Plus color={color} thickness={thickness} />;
      case "search":
        return <Search color={color} thickness={thickness} />;
      case "x-mark-circle":
        return <XmarkCircle color={color} thickness={thickness} />;
      case "x-mark":
        return <Xmark color={color} thickness={thickness} />;
      case "checkmark":
        return <Checkmark color={color} thickness={thickness} />;
      case "turn-left":
        return <TurnLeft color={color} thickness={thickness} />;
      case "turn-right":
        return <TurnRight color={color} thickness={thickness} />;
      case "at-sign":
        return <AtSign color={color} thickness={thickness} />;
      case "image-type":
        return <ImageType color={color} thickness={thickness} />;
      case "key":
        return <Key color={color} thickness={thickness} />;
      case "map-pin":
        return <MapPin color={color} thickness={thickness} />;
      case "no-sign":
        return <NoSign color={color} thickness={thickness} />;
      case "pen":
        return <Pen color={color} thickness={thickness} />;
      case "pencil":
        return <Pencil color={color} thickness={thickness} />;
      case "paper-plane":
        return <PaperPlane color={color} thickness={thickness} />;
      case "printer":
        return <Printer color={color} thickness={thickness} />;
      case "video":
        return <Video color={color} thickness={thickness} />;
      case "download":
        return <Download color={color} thickness={thickness} />;
      case "dots-horizontal":
        return <DotsHorizontal color={color} thickness={thickness} />;
      case "hamburger-menu":
        return <HamburgerMenu color={color} thickness={thickness} />;
      case "user":
        return <User color={color} />;
    }
  }

  return (
    <Container size={size} color={color} colorHover={colorHover}>
      {renderIcon()}
    </Container>
  );
};
