import React from "react";

interface TurnLeftProps {
  color: string;
  thickness?: string;
}

export const TurnLeft = ({ color, thickness }: TurnLeftProps) => {
  return (
    <svg
      width="800px"
      height="800px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9126 2.02905C11.2569 1.96564 11.6117 2.00663 11.9322 2.1461C12.2526 2.28549 12.5229 2.51629 12.7117 2.80727C12.9003 3.09808 12.9999 3.4371 13 3.78241L13 7.03095C16.7414 7.26795 19.2742 8.85318 20.8455 11.341C22.4987 13.9586 23 17.4414 23 21C23 21.424 22.7327 21.8018 22.3329 21.943C21.9331 22.0841 21.4878 21.9578 21.2216 21.6278C19.9872 20.0973 19.0943 18.9971 17.8244 18.2297C16.7356 17.5717 15.2945 17.1206 13 17.0208L13 20.2164C12.9999 20.5617 12.9003 20.9008 12.7117 21.1916C12.5229 21.4826 12.2526 21.7134 11.9322 21.8528C11.6117 21.9922 11.2569 22.0332 10.9126 21.9698C10.5684 21.9064 10.2524 21.742 10.0028 21.4997L1.54125 13.2827C1.36907 13.1154 1.23276 12.9151 1.14013 12.6947C1.04752 12.4744 1 12.238 1 11.9994C1 11.7609 1.04752 11.5244 1.14013 11.3041C1.23276 11.0838 1.36883 10.8837 1.54101 10.7164L10.0027 2.49935C10.2522 2.25697 10.5684 2.09244 10.9126 2.02905ZM11 4.31872L11 9H12C15.7048 9 17.8619 10.3624 19.1545 12.409C20.1133 13.927 20.6422 15.8935 20.8675 18.1422C20.2708 17.5324 19.6219 16.9791 18.8589 16.518C17.2035 15.5176 15.1099 15 12 15H11L11 19.6801L3.09059 11.9994L11 4.31872Z"
        fill={`${color ? color : "#280b60"}`}
        stroke={`${color ? color : "#280b60"}`}
        strokeWidth={`${thickness ? thickness : "0"}`}
        strokeLinecap="round"
        className="colorized--fill colorized--stroke"
      />
    </svg>
  );
};
