import React from "react";

interface MessageBubbleProps {
  color: string;
  thickness?: string;
}

export const MessageBubble = ({ color, thickness }: MessageBubbleProps) => {
  return (
    <svg
      width="800px"
      height="800px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 3C3.44772 3 3 3.44772 3 4V15C3 15.5523 3.44772 16 4 16H7C7.55228 16 8 16.4477 8 17V19.865L12.3598 16.2318C12.5395 16.082 12.7661 16 13 16H20C20.5523 16 21 15.5523 21 15V4C21 3.44772 20.5523 3 20 3H4ZM1 4C1 2.34315 2.34315 1 4 1H20C21.6569 1 23 2.34315 23 4V15C23 16.6569 21.6569 18 20 18H13.362L7.64018 22.7682C7.3421 23.0166 6.92723 23.0702 6.57584 22.9056C6.22446 22.741 6 22.388 6 22V18H4C2.34315 18 1 16.6569 1 15V4Z"
        fill={`${color ? color : "#280b60"}`}
        stroke={`${color ? color : "#280b60"}`}
        strokeWidth={`${thickness ? thickness : "0"}`}
        strokeLinecap="round"
        className="colorized--fill colorized--stroke"
      />
    </svg>
  );
};
