import { styled } from 'styled-components'

export const CheckboxContainer = styled.div<{ $checked?: boolean }>`
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: 2px solid black;
  cursor: pointer;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  input {
    width: 0;
    height: 0;
    opacity: 0;
    position: absolute;
  }

  background-color: ${({ $checked }) => ($checked ? '#cf4500' : 'white')};

  transition: background-color 0.2s ease-in;
`

export const CheckboxCheckmark = styled.div<{ $checked?: boolean }>`
  width: 100%;
  height: 100%;
  padding: 2px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;

  opacity: ${({ $checked }) => ($checked ? 1 : 0)};

  transition: opacity 0.2s ease-in;
`
