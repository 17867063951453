import { StoreContext } from '@/components/App'
import { Matrix } from '@/components/ProcessSteps/Matrix'
import { SignicatBranding } from '@/components/SignicatBranding/SignicatBranding'
import { API_URL, DEV_MODE } from '@/constants/envs'
import { axiosInstance } from '@/methods/axiosConfig'
import { DebtorData } from '@/store/AppState.store'

import { Page } from '@/styles/generic.styles'
import { observer } from 'mobx-react'
import { FC, useContext, useEffect } from 'react'

export const PersonalDetailsPage: FC = observer(() => {
  const store = useContext(StoreContext)
  const { step } = store.AppState

  const getData = async () => {
    try {
      if (DEV_MODE) return
      const { data } = await axiosInstance.get<{ debtor: DebtorData }>(`${API_URL}/api/debtor-data`)

      store.AppState.setDebtorData(data?.debtor)
      console.log(data)

      store.AppState.setStep('personalDetails')
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    const path = window.location.pathname
    if (path === '/fill-debtor-data') {
      void getData()
    } else if (path === '/terms') {
      store.AppState.setStep('generalConditions')
    }
  }, [])

  return (
    <Page>
      <Matrix step={step} />
      <SignicatBranding />
    </Page>
  )
})
